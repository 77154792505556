import React, { useContext, useEffect, useState } from "react";

import { ClientInformation } from "./components/clientInformation";
import { clientContext, Client } from "../../context/clientContext";
import "../../static/scss/account.scss";
import { servicesContext } from "../../context/servicesContext";

const MyAccount: React.FC = () => {
    const [clientValues, setClientValues] = useState<Client | null>(null);
    const { dataClient, refetchData, error } = useContext(clientContext);
    const { getData: getServiceData } = useContext(servicesContext);

    useEffect(() => {
        if (!clientValues || !dataClient || !error) {
            refetchData();
            setClientValues(dataClient);
            getServiceData();
        }
    }, [dataClient]);

    return <ClientInformation clientValues={clientValues} />;
};

export default MyAccount;

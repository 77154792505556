import { useContext } from "react";

import { context } from "../context/tableContext";

const useTable = () => {
    const { state, dispatch } = useContext<any>(context);

    const initTable = ({ head, body }: any) => {
        dispatch({ type: "INIT_TABLE", target: { head, body } });
    };

    const handleClearAllCheckBoxes = () => {
        dispatch({ type: "HANDLE_SELECTED", target: [] });
    };

    const handleAllCheckbox = (elements: any) => {
        const { selected } = state;
        if (selected.length === elements.length) {
            dispatch({ type: "HANDLE_SELECTED", target: [] });
        } else {
            dispatch({ type: "HANDLE_SELECTED", target: elements });
        }
    };

    const handleCheckbox = (id: any) => {
        const { selected } = state;
        const index = selected.indexOf(id);
        const tmp = [...selected];
        if (index !== -1) {
            tmp.splice(index, 1);
        } else {
            tmp.push(id);
        }
        dispatch({ type: "HANDLE_SELECTED", target: tmp });
    };

    const getChecked = (id: any) => {
        const { selected } = state;
        return selected.includes(id) ? "on" : "";
    };
    return {
        head: state.head,
        body: state.body,
        selected: state.selected,
        isLoaded: state.isLoaded,
        initTable,
        handleAllCheckbox,
        getChecked,
        handleCheckbox,
        handleClearAllCheckBoxes
    };
};

export default useTable;

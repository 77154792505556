import React from "react";

import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { addRefreshInterceptor, setAuthCookie } from "../../../api/authApi";
import { LoginInput, LoginResponse } from "../../../api/types";
import CustomButton from "../../../components/button/button";
import ErrorComponent from "../../../components/error/errorComponent";
import Input from "../../../components/input/Input";
import RoundedLoader from "../../../components/loader/RoundedLoaderAnimated";
import { useAuth } from "../../../hook/api/useAuth";
import { ReactComponent as LogoSvg } from "../../../static/images/logo-Blurit.svg";
import { isMyError } from "../../../types/error";
import { isAxiosError } from "axios";

type LoginValues = {
    email: string;
    password: string;
};

const initialValues: LoginValues = {
    email: "",
    password: ""
};

const LoginPlugin: React.FC = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const {
        logUser: { isLoading: loading, isError, mutate, error }
    } = useAuth();

    const isFormInValid = (values: LoginValues): boolean => {
        return values.email.length <= 3 || values.password.length <= 3;
    };

    const onSuccess = (data: LoginResponse) => {
        try {
            setAuthCookie(data);
            addRefreshInterceptor(push);
            push("/login/plugin/success");
        } catch (e) {
            console.error(e);
        }
    };

    const submitLogin = (params: LoginInput) => {
        mutate({ ...params, page: "login-plugin" }, { onSuccess });
    };

    return (
        <div className="row h-100" style={{ marginRight: 0 }}>
            <div className="col-12">
                <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-column flex-start auth-container plugin-container">
                        <div>
                            <div className="mb-5 d-flex justify-content-center">
                                <LogoSvg />
                            </div>
                            <div className="mb-2">
                                <h1 className="mb-3">{t(`login-plugin.title`)}</h1>
                                <p id="login-laius">{t(`login-plugin.laius`)}</p>
                            </div>
                        </div>

                        <Formik initialValues={initialValues} onSubmit={submitLogin}>
                            {({ values, handleChange }) => {
                                return (
                                    <Form className="d-flex flex-column justify-content-between">
                                        <Input
                                            isInvalid={isError}
                                            id="login"
                                            name="email"
                                            icon="login"
                                            value={values.email}
                                            placeholder={t("login-plugin.emailPlaceholder")}
                                            isRequired
                                            onChange={handleChange}
                                            inputType="text"
                                            inputClassName="rounded"
                                            labelTranslation={t("login-plugin.emailLabel")}
                                        />
                                        <Input
                                            isInvalid={isError}
                                            id="password"
                                            icon="password"
                                            name="password"
                                            value={values.password}
                                            placeholder={t("login-plugin.passwordPlaceholder")}
                                            isRequired
                                            onChange={handleChange}
                                            inputType="password"
                                            inputClassName="rounded"
                                            labelTranslation={t("login-plugin.passwordLabel")}
                                            maskable={true}
                                        />
                                        {
                                            <div className="d-flex justify-content-center mb-3 mt-3">
                                                {loading ? (
                                                    <RoundedLoader isLoading={loading} />
                                                ) : (
                                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                                        <CustomButton
                                                            customClass="mb-3"
                                                            disabled={isFormInValid(values)}
                                                            buttonText={t("login-plugin.signIn")}
                                                            classNameType="mainRounded"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        }
                                        {isError && isAxiosError(error) ? (
                                            isMyError(error.response?.data) ? (
                                                <div className="error">
                                                    {error.response?.data.message}
                                                </div>
                                            ) : (
                                                <ErrorComponent
                                                    error={error}
                                                    customError={
                                                        t(
                                                            "login-plugin.invalidCredentials"
                                                        ) as string
                                                    }
                                                />
                                            )
                                        ) : null}
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LoginPlugin;

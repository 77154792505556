import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import CustomButton from "../components/button/button";
import TaskButton from "../components/mobile/task/TaskButton";
import Modal, { IModal } from "../components/modal/Modal";

const acountPages = ["/account", "/account/plan", "/account/developer"] as const;
type AccountPage = (typeof acountPages)[number];

const AccountLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const { pathname } = useLocation();
    const [modalState, setModalState] = useState({ show: false } as IModal);

    const handleGoToPage = (goTo: AccountPage) => {
        push(goTo);
    };

    useEffect(() => {
        if (pathname.includes("update-payment-method-success")) {
            setModalState({
                show: true,
                showCancel: false,
                message: t("account-management.modal-payment-method"),
                onConfirm: () => {
                    handleGoToPage("/account");
                    setModalState({ show: false });
                }
            });
        } else {
            setModalState({ show: false });
        }
    }, []);

    return (
        <>
            <div className="page-header d-flex justify-content-between mb-3 mb-lg-5">
                <div className="d-flex justify-content-start" role="tablist">
                    <CustomButton
                        customClass={`${
                            !pathname.includes("plan") && !pathname.includes("developer")
                                ? "active"
                                : ""
                        } me-lg-4`}
                        classNameType="tabButton"
                        buttonText={t("account-management.tab-general")}
                        buttonType="button"
                        onClick={() => handleGoToPage("/account")}
                        role={"tab"}
                        ariaSelected={!pathname.includes("invoice") && !pathname.includes("plan")}
                    />
                    <CustomButton
                        customClass={`${pathname.includes("plan") ? "active" : ""}  me-lg-4`}
                        classNameType="tabButton"
                        buttonText={t("account-management.tab-plan")}
                        buttonType="button"
                        onClick={() => handleGoToPage("/account/plan")}
                        role={"tab"}
                        ariaSelected={
                            !pathname.includes("invoice") && !pathname.includes("services")
                        }
                    />

                    <CustomButton
                        customClass={`${pathname.includes("developer") ? "active" : ""}`}
                        classNameType="tabButton"
                        buttonText={t("account-management.tab-developer")}
                        buttonType="button"
                        onClick={() => handleGoToPage("/account/developer")}
                        role={"tab"}
                        ariaSelected={pathname.includes("developer")}
                    />
                </div>
            </div>
            {children}
            <Modal {...modalState} />
            <TaskButton />
        </>
    );
};

export default AccountLayout;

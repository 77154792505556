import React from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import CustomButton from "../../../../components/button/button";
import { Client } from "../../../../context/clientContext";

export const ClientInformation: React.FC<{ clientValues: Client | null }> = ({ clientValues }) => {
    const { t } = useTranslation();
    const { push } = useHistory();

    const handlePageModification = () => {
        push(`account/edit`);
    };

    return (
        <div className="middle-container my-account-view">
            <div className="w-100 d-flex align-items-center justify-content-between mb-3 mb-lg-5">
                <div className="d-flex align-items-center">
                    <h2 className="mb-0 me-lg-4 account-title">
                        {t("account-management.client-information")}
                    </h2>
                </div>
                <div>
                    <CustomButton
                        classNameType="mainWhite"
                        onClick={handlePageModification}
                        buttonText={t("account-management.modification")}
                        buttonType="button"
                    />
                </div>
            </div>
            <div className="d-flex flex-column gap-5">
                <section className="d-flex gap-3 flex-column justify-content-start">
                    <div className="d-flex align-items-center">
                        <span className="mr-1 text-bold">{t("account-management.mail")}</span>
                        <span className="account-value">{clientValues?.mail || "-"}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="mr-1 text-bold">{t("account-management.last-name")}</span>
                        <span className="account-value">{clientValues?.lastName || "-"}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="mr-1 text-bold">{t("account-management.first-name")}</span>
                        <span className="account-value">{clientValues?.firstName || "-"}</span>
                    </div>
                    <div className="account-label-phone d-flex align-items-center">
                        <span className="mr-1 text-bold">{t("account-management.phone")}</span>
                        <span className="account-value">{clientValues?.phone || "-"}</span>
                    </div>
                </section>
                <section className="d-flex gap-3 flex-column justify-content-start">
                    <div className="d-flex align-items-center">
                        <span className="mr-1 text-bold">{t("account-management.address")}</span>
                        <span className="account-value">{clientValues?.address || "-"}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="mr-1 text-bold">{t("account-management.city")}</span>
                        <span className="account-value">{clientValues?.city || "-"}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-md-start">
                        <span className="mr-1 text-bold">{t("account-management.country")}</span>
                        <span className="account-value">{clientValues?.country || "-"}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="mr-1 text-bold">
                            {t("account-management.postal-code")}
                        </span>
                        <span className="account-value">{clientValues?.postalCode || "-"}</span>
                    </div>
                </section>
                {clientValues?.type === "COMPANY" && (
                    <section className="d-flex gap-3 flex-column justify-content-start">
                        <div className="d-flex align-items-center">
                            <span className="mr-1 text-bold">
                                {t("account-management.company-name")}
                            </span>
                            <span className="account-value">
                                {clientValues?.companyName || "-"}
                            </span>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="mr-1 text-bold">
                                {t("account-management.company-id")}
                            </span>
                            <span className="account-value">{clientValues?.companyId || "-"}</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="mr-1 text-bold">
                                {t("account-management.company-vat")}
                            </span>
                            <span className="account-value">{clientValues?.vatNumber || "-"}</span>
                        </div>
                    </section>
                )}
            </div>
        </div>
    );
};

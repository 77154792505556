import React, { useContext, useMemo, useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { licensesContext } from "../../../context/licensesContext";
import { useGetClient } from "../../../hook/api/client";
import { useHeader } from "../../../hook/useHeader";
import { TASKS_UNAVAILABLE_TYPES, serviceUsableState } from "../../../recoil/services.atom";
import { ReactComponent as LogoWIS } from "../../../static/images/logo-Blurit.svg";
import { ErrorHeaderCardExpired } from "../../error/ErrorHeaderCardExpired";
// Style
import "./menuMobile.scss";
// Logo
import { ErrorHeaderPluginDisabled } from "../../error/ErrorHeaderPluginDisabled";

type Menu = {
    active: boolean; // Get
    setActive: React.Dispatch<React.SetStateAction<boolean>>; // Set
};

const MenuMobilePlugin: React.FC<Menu> = ({ active, setActive }) => {
    const { t } = useTranslation();
    const { handleLogout } = useHeader();
    const serviceUsable = useRecoilValue(serviceUsableState);
    const { data: licenses } = useContext(licensesContext);
    const { data } = useGetClient();
    const name = data?.name;
    const firstName = data?.firstName;
    const email = data?.mail;
    const initials = name?.substring(0, 2);
    const pluginUsable = useMemo(() => !licenses?.find((license) => license.blocked), [licenses]);
    const { push } = useHistory();

    const navBarItems = [
        {
            linkTo: "/plugin/ressources",
            title: t("navbar.ressources"),
            icon: "icon-ressources",
            translation: t("navbar.ressources")
        }
    ];

    return (
        <div
            className={`menu-mobile ${active ? "show" : ""}`}
            aria-hidden={!active}
            tabIndex={active ? 0 : -1}
        >
            <div className="menu-mobile-header">
                <div className="logo">
                    <LogoWIS />
                </div>
                <button
                    type="button"
                    className="button btn-menu active"
                    onClick={() => setActive(!active)}
                ></button>
            </div>

            <div className="menu-mobile-body">
                <nav role="navigation">
                    <ul>
                        {navBarItems.map((element, key) => (
                            <li key={key}>
                                <NavLink
                                    to={element.linkTo}
                                    className={element.icon}
                                    activeClassName="active"
                                    onClick={() => setActive(!active)}
                                >
                                    {element.title}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </nav>

                {!serviceUsable.status &&
                    serviceUsable.reason === TASKS_UNAVAILABLE_TYPES.CARD_EXPIRED && (
                        <div className="trial trial-error">
                            <ErrorHeaderCardExpired />
                        </div>
                    )}

                {serviceUsable.status && !pluginUsable && (
                    <div className="trial">
                        <ErrorHeaderPluginDisabled />
                    </div>
                )}
            </div>

            <div className="menu-mobile-footer d-flex flex-column">
                <div className="d-flex justify-content-between">
                    <div
                        onClick={() => push("/plugin/account")}
                        className="d-flex align-items-center justify-content-center gap-4"
                    >
                        <div className="menu-mobile-footer-account-badge">{initials}</div>
                        <div className="d-flex flex-column">
                            <span className="name">{firstName}</span>
                            <span>{email}</span>
                        </div>
                    </div>
                    <button type="button" className="button btn-deco" onClick={handleLogout} />
                </div>

                <p className="copyright">&copy; {moment().year()} Wassa - All right reserved</p>
            </div>
        </div>
    );
};

export default MenuMobilePlugin;

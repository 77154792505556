import { useContext, useEffect, useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";

import CustomButton from "../../../../components/button/button";
import { RoundedLoader } from "../../../../components/loader/RoundedLoader";
import Modal, { IModal } from "../../../../components/modal/Modal";
import { licensesContext } from "../../../../context/licensesContext";
import { License, PaymentRecurrence } from "../../../../types/license";

export const VideoPlugins: React.FC = () => {
    const { t } = useTranslation();
    const { data, updateLicenseAutomaticRenewal, loading, error } = useContext(licensesContext);
    const [modalState, setModalState] = useState({} as IModal);
    const license = data?.[0];

    const openModalPluginAutomaticRenewal = () => {
        if (!license) return;
        const automaticRenewalUpdated = !license.automaticRenewal;

        const title = automaticRenewalUpdated
            ? t("services-management.plugin.modal.title.activate")
            : t("services-management.plugin.modal.title.deactivate");

        const message = automaticRenewalUpdated
            ? t("services-management.plugin.modal.message.activate")
            : t("services-management.plugin.modal.message.deactivate", {
                  endDate:
                      license.paymentRecurrence === PaymentRecurrence.MONTHLY
                          ? moment.utc().endOf("month").endOf("day").format("DD/MM/YYYY")
                          : moment.utc().endOf("year").endOf("day").format("DD/MM/YYYY")
              });

        setModalState({
            show: true,
            showCancel: true,
            title,
            message,
            onConfirm: () => {
                setModalState({ ...modalState, show: false });
                updateLicenseAutomaticRenewal(license.uuid, {
                    automaticRenewal: automaticRenewalUpdated
                });
            },
            onCancel: () => setModalState({ ...modalState, show: false }),
            confirmBtnText: t("services-management.plugin.modal.confirm"),
            cancelBtnText: t("services-management.plugin.modal.cancel")
        });
    };

    const getLicenseActive = () => {
        if (loading || !license) return "-";
        if (license.blocked || moment().endOf("day").isAfter(license.dateEnd))
            return t("services-management.plugin.deactivated");
        return t("services-management.plugin.activated");
    };

    const isTrial = () => {
        return license?.dateStart && moment.utc(license.dateStart).add(7, "days").isAfter(moment());
    };

    useEffect(() => {
        if (error) {
            setModalState({
                show: true,
                showCancel: false,
                title: t("error.error-title"),
                message: t("error.internalError"),
                onConfirm: () => {
                    setModalState({ ...modalState, show: false });
                },
                confirmBtnText: t("services-management.plugin.modal.confirm"),
                cancelBtnText: t("services-management.plugin.modal.cancel")
            });
        }
    }, [error]);

    return (
        <div className="middle-container my-account-view">
            <div className="row flex-row">
                <div className="w-100 d-flex align-items-center justify-content-between mb-3 mb-lg-5">
                    <div className="d-flex align-items-center  account-title-container">
                        <h2 className="account-title mb-0 me-lg-4">
                            {t("services-management.plugin.video-plugin")}
                        </h2>
                        <RoundedLoader isLoading={loading} />
                    </div>
                    <CustomButton
                        classNameType="mainWhite"
                        onClick={openModalPluginAutomaticRenewal}
                        buttonText={
                            license
                                ? license.dateEnd
                                    ? t("services-management.plugin.renewal")
                                    : t("services-management.plugin.stop-renewal")
                                : "-"
                        }
                    />
                </div>
            </div>
            <div className="d-flex flex-column gap-3">
                <div className="row align-items-center">
                    <div className="col-5 col-lg-2">
                        <span className="mr-1 text-bold">
                            {t("services-management.plugin.status")}
                        </span>
                    </div>
                    <div className="col-7 col-lg-10">
                        <span className="account-value">
                            <div className="plan-container">{getLicenseActive()}</div>
                        </span>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-5 col-lg-2">
                        <span className="mr-1 text-bold">
                            {t("services-management.plugin.billing-period")}
                        </span>
                    </div>
                    <div className="col-7 col-lg-10">
                        <span className="account-value">
                            <div className="plan-container">
                                {license?.paymentRecurrence ?? "-"}
                            </div>
                        </span>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-3 col-lg-2">
                        <span className="mr-1 text-bold">
                            {t("services-management.plugin.commitment-end-date")}
                        </span>
                    </div>
                    <div className="col-7 col-lg-3">
                        <span className="account-value">
                            <div className="plan-container">
                                {license
                                    ? license.dateEnd
                                        ? moment.utc(license.dateEnd).format("DD/MM/YYYY")
                                        : t("services-management.plugin.no-ending-date")
                                    : "-"}
                            </div>
                        </span>
                    </div>
                    {isTrial() ? (
                        <>
                            <div className="col-5 col-lg-2">
                                <span className="mr-1 text-bold">
                                    {t("services-management.plugin.trial-ending-date")}
                                </span>
                            </div>
                            <div className="col-7 col-lg-3">
                                <span className="account-value">
                                    <div className="d-block">
                                        {moment
                                            .utc((license as License).dateStart)
                                            .add(7, "days")
                                            .format("DD/MM/YYYY")}
                                    </div>
                                </span>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            <Modal {...modalState} />
        </div>
    );
};

import React, { ButtonHTMLAttributes, CSSProperties, DetailedHTMLProps } from "react";
import "./button.scss";

interface IButton {
    buttonType?: "submit" | "button";
    buttonText?: string | React.ReactNode;
    onClick?: (e: any) => void;
    classNameType?:
        | "main"
        | "secondary"
        | "cancel"
        | "mainBordered"
        | "mainRounded"
        | "disconnect"
        | "mainWhite"
        | "arrowButton"
        | "tabButton"
        | "account"
        | "mainInverted"
        | "squarred"
        | "close"
        | "menu"
        | "none"
        | "desktopTask";
    customClass?: string;
    disabled?: boolean;
    withLoader?: boolean;
    style?: CSSProperties;
    role?: string;
    icon?: JSX.Element;
    ariaSelected?: boolean; // WAI-ARIA => https://developer.mozilla.org/en-US/docs/Learn/Accessibility/WAI-ARIA_basics
}

const CustomButton: React.FC<
    DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & IButton
> = ({
    buttonType = "submit",
    classNameType = "main",
    buttonText = "",
    onClick,
    disabled = false,
    customClass = "",
    withLoader = false,
    style,
    role,
    ariaSelected,
    icon,
    onMouseEnter,
    onMouseLeave,
    onPointerEnter,
    onPointerLeave,
    id
}) => {
    const buttonTypeClass = {
        main: "button btn-main",
        secondary: "button btn-secondary",
        cancel: "button btn-cancel-dark",
        mainBordered: "button btn-main bordered",
        mainRounded: "button btn-main-rounded",
        mainInverted: "button btn-main-inverted",
        mainWhite: "button btn-white-main",
        disconnect: "button btn-disconnect",
        arrowButton: "button collapse-btn",
        tabButton: "button tab-button",
        account: "button btn-account",
        squarred: "button btn-squarred",
        close: "button btn-close",
        menu: "button btn-menu",
        desktopTask: "button btn-desktop-task",
        none: ""
    };

    return (
        <button
            id={id}
            disabled={disabled}
            className={`${buttonTypeClass[classNameType]} ${customClass} ${withLoader ? "with-loader" : ""}`}
            onClick={onClick}
            type={buttonType}
            style={style}
            role={role}
            aria-selected={ariaSelected}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
        >
            {icon && icon}
            {buttonText}
        </button>
    );
};

export default CustomButton;

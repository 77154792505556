import React, { useState } from "react";

import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useGetClient } from "../../../hook/api/client";
import { useHeader } from "../../../hook/useHeader";
import { TASKS_UNAVAILABLE_TYPES, serviceUsableState } from "../../../recoil/services.atom";
import { convertSize } from "../../../services/tools";
import { ReactComponent as LogoWIS } from "../../../static/images/logo-Blurit.svg";
import { ErrorHeaderCardExpired } from "../../error/ErrorHeaderCardExpired";
// Style
import "./menuMobile.scss";
// Logo
import { ErrorHeaderServiceDisabled } from "../../error/ErrorHeaderServiceDisabled";

type Menu = {
    active: boolean; // Get
    setActive: React.Dispatch<React.SetStateAction<boolean>>; // Set
};

const MenuMobile: React.FC<Menu> = ({ active, setActive }) => {
    const { t } = useTranslation();
    const { handleLogout, getTrialState, isTrial } = useHeader();
    const { push } = useHistory();
    const [help, setHelp] = useState<boolean>(false);
    const serviceUsable = useRecoilValue(serviceUsableState);
    const location = useLocation();
    const { data } = useGetClient();
    const name = data?.name;
    const firstName = data?.firstName;
    const email = data?.mail;
    const initials = name?.substring(0, 2);

    const handleHelp = () => {
        setHelp((prev) => !prev);
    };

    const navBarItems = [
        {
            linkTo: "/task-manager",
            title: t("navbar.task-manager"),
            icon: "icon-taskmanager",
            translation: t("navbar.task-manager")
        },
        {
            linkTo: "/dashboard",
            title: t("navbar.dashboard"),
            icon: "icon-dashboard",
            translation: t("navbar.dashboard")
        }
    ];

    const redirectAndClose = (url: string) => {
        setActive(!active);
        push(url);
    };

    return (
        <div
            className={`menu-mobile ${active ? "show" : ""}`}
            aria-hidden={!active}
            tabIndex={active ? 0 : -1}
        >
            <div className="menu-mobile-header">
                <div className="logo">
                    <LogoWIS />
                </div>

                <button
                    type="button"
                    className="button btn-menu active"
                    onClick={() => setActive(!active)}
                ></button>
            </div>

            <div className="menu-mobile-body">
                <nav role="navigation">
                    <ul>
                        {navBarItems.map((element, key) => (
                            <li key={key}>
                                <NavLink
                                    to={element.linkTo}
                                    className={element.icon}
                                    activeClassName="active"
                                    onClick={() => setActive(!active)}
                                >
                                    {element.title}
                                </NavLink>
                            </li>
                        ))}
                        {/* Specific case */}
                        <li>
                            <a
                                href="#!"
                                className={`icon-help${help ? " active" : ""}`}
                                onClick={handleHelp}
                            >
                                {t("navbar.help.title")}
                            </a>
                        </li>
                    </ul>
                </nav>
                {help && (
                    <div className="helper mt-3" style={{ display: "block" }}>
                        <h4 className="helper__title">{t("navbar.help.title")}</h4>
                        <p className="helper__text">
                            <Trans
                                i18nKey={"navbar.help.text"}
                                components={{
                                    1: (
                                        <a
                                            href={`${process.env.REACT_APP_BLURIT_LANDING_FRONT_ENDPOINT}/contact#faq`}
                                            target="_blank"
                                            rel="noreferrer"
                                        ></a>
                                    ),
                                    2: <a href="mailto:support@blurit.io"></a>
                                }}
                            ></Trans>
                        </p>
                    </div>
                )}

                {isTrial && !location.pathname.includes("plugin") && (
                    <div className="trial">
                        <div className="trial-text">
                            <p className="title">TRIAL VERSION</p>
                            <p className="data">
                                {getTrialState() ? convertSize(getTrialState()) : null} remaining
                            </p>
                        </div>

                        <div className="trial-action">
                            <button
                                type="button"
                                className="button btn-main"
                                onClick={() => redirectAndClose("/account/plan")}
                            >
                                Update plan
                            </button>
                        </div>
                    </div>
                )}

                {serviceUsable.reason === TASKS_UNAVAILABLE_TYPES.CARD_EXPIRED && (
                    <div className="trial trial-error">
                        <ErrorHeaderCardExpired />
                    </div>
                )}

                {serviceUsable.reason === TASKS_UNAVAILABLE_TYPES.INACTIVE_SERVICES && (
                    <div className="trial">
                        <ErrorHeaderServiceDisabled />
                    </div>
                )}
            </div>

            <div className="menu-mobile-footer d-flex flex-column">
                <div className="d-flex justify-content-between">
                    <div
                        className="d-flex align-items-center justify-content-center gap-4"
                        onClick={() => {
                            push("/account");
                            setActive(false);
                        }}
                    >
                        <div className="menu-mobile-footer-account-badge">{initials}</div>
                        <div className="d-flex flex-column">
                            <span className="name">{firstName}</span>
                            <span>{email}</span>
                        </div>
                    </div>
                    <button type="button" className="button btn-deco" onClick={handleLogout} />
                </div>

                <p className="copyright">&copy; {moment().year()} Wassa - All right reserved</p>
            </div>
        </div>
    );
};

export default MenuMobile;

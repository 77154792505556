import React, { useContext, useEffect, useState } from "react";

import { CreditCard } from "./CreditCard";
import Invoices from "./Invoices";
import { PlanAndOptions } from "./PlanAndOptions";
import { clientContext, Client } from "../../../context/clientContext";
import "../../../static/scss/account.scss";

export const PlanAndPayment = () => {
    const [clientValues, setClientValues] = useState<Client | null>(null);
    const { dataClient, refetchData, error } = useContext(clientContext);

    useEffect(() => {
        if (!clientValues || !dataClient || !error) {
            refetchData();
            setClientValues(dataClient);
        }
    }, [dataClient]);

    return (
        <>
            <PlanAndOptions clientValues={clientValues} />
            <div className="mb-3" />
            {!clientValues?.isTrial && (
                <>
                    <CreditCard clientValues={clientValues} />
                    <div className="mb-3" />
                    <Invoices />
                </>
            )}
        </>
    );
};

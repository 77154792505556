import { MouseEventHandler, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";

import { useGetClient } from "../../../hook/api/client";
import { useHeader } from "../../../hook/useHeader";
import CustomButton from "../../button/button";

type MenuNavLinks = Array<{ goTo: string; translation: string }>;

export const MenuDropdown: React.FC<{ navLinks: MenuNavLinks }> = ({ navLinks }) => {
    const { data } = useGetClient();
    const menuRef = useRef<HTMLDivElement>(null!);
    const [menuOpen, setMenuOpen] = useState(false);
    const { t } = useTranslation();
    const { handleLogout } = useHeader();
    const { push } = useHistory();

    useEffect(() => {
        if (!menuRef || !menuRef.current) return;

        const handleClickOutsideMenu = (ev: MouseEvent) => {
            if (!menuRef.current.contains(ev.target as Node)) setMenuOpen(false);
        };

        document.addEventListener("click", handleClickOutsideMenu);
        return () => {
            document.removeEventListener("click", handleClickOutsideMenu);
        };
    }, []);

    const name = data?.name;
    const firstName = data?.firstName;
    const email = data?.mail;
    const initials = name?.substring(0, 2);

    const handleClickOpenMenu: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (!menuOpen) {
            e.preventDefault();
            e.stopPropagation();
        }
        setMenuOpen((prev) => !prev);
    };

    const handleClickUserArea = () => {
        push("/account");
        setMenuOpen(false);
    };

    return (
        <div className="menu-dropdown-container d-none d-lg-block">
            <CustomButton
                buttonType="button"
                buttonText={initials}
                customClass="d-block"
                classNameType="account"
                onClick={handleClickOpenMenu}
            />
            <div
                ref={menuRef}
                className={`menu-dropdown ${menuOpen ? "d-flex flex-column" : "d-none"}`}
            >
                <section className="user-area pb-3" onClick={handleClickUserArea}>
                    <div className="d-flex align-items-center gap-3">
                        <div className="account-badge">{initials}</div>
                        <div className="d-flex flex-column">
                            <span className="name">{firstName}</span>
                            <span className="email">{email}</span>
                        </div>
                    </div>
                </section>
                <section>
                    <ul>
                        {navLinks.map((item, key) => (
                            <li key={key}>
                                <NavLink to={item.goTo}>
                                    <span>{item.translation}</span>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </section>
                <section className="d-flex justify-content-end">
                    <CustomButton
                        classNameType="mainWhite"
                        onClick={handleLogout}
                        buttonText={t("menu-dropdown.logout")}
                        customClass="mt-3"
                    />
                </section>
            </div>
        </div>
    );
};
